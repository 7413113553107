<template>
  <v-container
    fluid
    py-0
    class="blue"
    style="position: fixed; overflow: scroll; height: 100vh"
  >
    <!-- App Alert -->
    <v-alert
      slot="not-chrome"
      v-show="alert.notChrome"
      color="yellow"
      icon="feather-alert-triangle"
      class="sweeep-not-chrome-alert"
    >
      {{ $t('message.chromeAlert') }}
    </v-alert>
    <v-row align="center" justify="center" style="height: 100vh">
      <v-card class="pa-8 ma-5" width="380">
        <v-card-title class="d-flex justify-center"> sweeep </v-card-title>
        <v-form ref="form" v-model="valid">
          <v-text-field
            label="Eメール"
            v-model="email"
            :rules="emailRules"
            lazy-validation
            required
            validate-on-blur
            @keyup.enter="submit"
            prepend-inner-icon="feather-mail"
            class="mb-5"
          />
          <v-text-field
            label="パスワード"
            v-model="password"
            :type="show_password ? 'text' : 'password'"
            :rules="passwordRules"
            :append-icon="show_password ? 'feather-eye-off' : 'feather-eye'"
            required
            validate-on-blur
            @keyup.enter="submit"
            prepend-inner-icon="feather-lock"
            class="mb-5"
            @click:append="show_password = !show_password"
          />
          <v-btn :loading="loading" block @click="submit" color="blue" dark>{{
            $t('message.loginNow')
          }}</v-btn>
          <router-link
            class="mb-4 mt-2 caption"
            to="/session/forgot-password"
            >{{ $t('message.forgotPassword') }}</router-link
          >

          <!-- サイトを開放して誰でも登録できるようになるまではコメントアウト -->
          <!-- <v-btn block @click="onCreateAccount">{{$t('message.createAccount')}}</v-btn> -->
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import SessionSliderWidget from 'Components/Widgets/SessionSlider'
import AppConfig from 'Constants/AppConfig'
import Vue from 'vue'
import AuthService from '../../auth/AuthService'
import Snackbar from 'Helpers/snackbar/index'

const auth = new AuthService()
const { login, logout, authenticated, authNotifier } = auth

export default {
  components: {
    SessionSliderWidget,
  },
  data() {
    return {
      checkbox: false,
      valid: false,
      email: '',
      emailRules: [
        (v) => !!v || '※この項目は必須です',
        (v) =>
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            v
          ) || '正しい形式で入力ください',
      ],
      password: '',
      passwordRules: [(v) => !!v || '※この項目は必須です'],
      appLogo: AppConfig.appLogo2,
      brand: AppConfig.brand,
      loading: false,
      alert: { notChrome: false },
      show_password: false,
    }
  },
  mounted() {
    this.checkBrowserInfo()
  },
  methods: {
    submit() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true
          const apiUrl = this.$store.getters.apiLoginFunction
          const user = {
            email: this.email,
            password: this.password,
            envtype: this.$store.getters.getEnvType,
            organization_name: '',
          }
          let next = this.$route.query.next
          this.$store
            .dispatch('apiSignInUser', {
              user,
              apiUrl,
              next,
            })
            .then((response) => {
              if (response.data.error === 'Unable to login.') {
                Snackbar.error(this.$t('message.login_unable'))
              } else if (response.data.error) {
                Snackbar.error(this.$t('message.login_invalid_user_pass'))
              }
              this.loading = false
            })
        }
      } catch (e) {
        console.log(e)
      }
    },
    checkBrowserInfo() {
      let agent = window.navigator.userAgent.toLowerCase()
      var chrome =
        agent.indexOf('chrome') !== -1 &&
        agent.indexOf('edge') === -1 &&
        agent.indexOf('opr') === -1
      if (!chrome) {
        this.alert.notChrome = true
      } else {
        return
      }
    },
    onCreateAccount() {
      try {
        this.$router.push('/session/sign-up')
      } catch {}
    },
  },
}
</script>
